import { i18n } from '$lib/i18n';
import { init } from '@jill64/sentry-sveltekit-cloudflare/client';

export const reroute = i18n.reroute();

const onError = init(
	'https://73ef4c6859a84cc24264db7ce61bed97@o4507124090994688.ingest.de.sentry.io/4507124093681744'
	//	{
	//		sentryOptions: {
	//			beforeSend: (event) =>
	//				errorForTesting.includes(event.exception?.values?.[0].value ?? '') ? null : event
	//		}
	//	}
);

export const handleError = onError(
	(e: { status?: number; message?: string }, sentryEventId?: string) => {
		console.error('Error occurred:', e);
		if (sentryEventId) {
			console.log('Sentry Event ID:', sentryEventId);
		}
		return {
			status: e.status || 500,
			message: e.message || 'Internal error: we are working on fixing this issue',
			sentryEventId: sentryEventId || ''
		};
	}
);
